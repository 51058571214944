import React, { useState, useContext, useRef, useEffect } from 'react'
import { Context } from '../../context'
import Block from '../../settings/stories.json'
import Layout from '../../layout/layout'
import FooterImage from '../../layout/main/components/footerImage'
import * as styles from './story.module.scss'
import parse from 'html-react-parser'
import { compareDate, filterResults, FormatDate, SearchResults } from '../../utilities'
import { Link } from 'gatsby'

type storyProps = {
  pageContext: {
    title: string,
    image?: string,
    date: string,
    body: string,
    subheading?: string,
    stories?: [any],
    searchIndexes?: [any]
  }
}

export default ({ pageContext: { title, image, date, body, subheading, stories, searchIndexes } }: storyProps) => {
  const [searchResults, setSearchResults] = useState([]);
  const context = useContext(Context);
  const [show, setShow] = useState(false);
  stories.sort(compareDate)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)
  },[])

  useEffect(() => {
    if (searchIndexes && context) {
      const results = filterResults(searchIndexes, context);
      if (results)
        context.setMenuOpen(false);
      setSearchResults(results);
    }
  }, [context && context.search])

  return (
    <Layout title={title} >
      <section className={styles.section + ` ${show ? `${styles.show}` : ''}`}>
        <div className={styles.buttonContainer}>
          <Link to={`/stories-of-impact#stories`} className={styles.button}>
          <span><img src='/button.svg' className={styles.icon} /></span>
            Go back
          </Link>
        </div>
        <p className={styles.date}>Posted <span><FormatDate date={date} /></span></p>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.subheading}>{subheading && parse(subheading)}</div>
        {image &&
        <img src={image} className={styles.image} />}
        <div className={styles.body}>{parse(body)}</div>
      </section>
      <SearchResults results={searchResults} />
    </Layout>
  )
}