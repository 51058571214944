// extracted by mini-css-extract-plugin
export var body = "story-module--body--MSOBz";
export var button = "story-module--button--PRoXu";
export var buttonContainer = "story-module--buttonContainer--cbk-w";
export var date = "story-module--date--PNt+d";
export var icon = "story-module--icon--x0AxN";
export var image = "story-module--image--Ts+7n";
export var section = "story-module--section--TM-ig";
export var show = "story-module--show--JNBYo";
export var subheading = "story-module--subheading--q+Ce0";
export var title = "story-module--title--xW2Rp";